<template>
  <CRow class="justify-content-center">
    <CCol col="12" md="9" lg="7">
      <CCard class="p-4 pb-5 shadow-none col-12" color="second-card-bg">
        <CCardBody>
          <div class="cheader">
            <a :href="homepage_url">
              <logo-wrap :auth="true" />
            </a>
            <div class="d-flex align-items-center">
              <div class="mr-3 cursor-pointer" @click="darkMode = !darkMode">
                <CIcon v-if="darkMode" name="cil-sun"/>
                <CIcon v-else name="cil-moon"/>
              </div>
              <CSelect
                :value.sync="locale"
                :options="$store.state.localeOptions"
                class="mb-0"
              />
            </div>
          </div>
          <h1 class="title">{{ $t('formSignUp.confirmationTitle') }}</h1>
          <h5>{{ $t('formSignUp.expiredText') }}</h5>
          <router-link :to="{name: 'signin'}">
            <CButton color="primary">
              {{ $t('formSignUp.signIn') }}
            </CButton>
          </router-link>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LogoWrap from "@/components/LogoWrap";

export default {
  name: "ExpiredHash",
  components: {
    LogoWrap
  },
  computed: {
    locale: {
      get() {
        return this.$root.$i18n.locale
      },
      set(lang) {
        this.$root.$i18n.locale = lang
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
    },
    darkMode: {
      get() {
        return this.$store.getters.darkMode
      },
      set(val) {
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'darkMode',
          value: val
        })
      }
    },
    homepage_url() {
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if(hp && hp.slice(-1) !== '/'){
        hp += '/'
      }
      if(this.locale === 'ru'){
        hp += 'ru/';
      }
      return hp;
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @include media-breakpoint-down(xs) {
    font-size: 1.5rem;
  }
}

.cheader {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
