var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    { staticClass: "justify-content-center" },
    [
      _c(
        "CCol",
        { attrs: { col: "12", md: "9", lg: "7" } },
        [
          _c(
            "CCard",
            {
              staticClass: "p-4 pb-5 shadow-none col-12",
              attrs: { color: "second-card-bg" },
            },
            [
              _c(
                "CCardBody",
                [
                  _c("div", { staticClass: "cheader" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.homepage_url } },
                      [_c("logo-wrap", { attrs: { auth: true } })],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mr-3 cursor-pointer",
                            on: {
                              click: function ($event) {
                                _vm.darkMode = !_vm.darkMode
                              },
                            },
                          },
                          [
                            _vm.darkMode
                              ? _c("CIcon", { attrs: { name: "cil-sun" } })
                              : _c("CIcon", { attrs: { name: "cil-moon" } }),
                          ],
                          1
                        ),
                        _c("CSelect", {
                          staticClass: "mb-0",
                          attrs: {
                            value: _vm.locale,
                            options: _vm.$store.state.localeOptions,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.locale = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("formSignUp.confirmationTitle"))),
                  ]),
                  _c("h5", [_vm._v(_vm._s(_vm.$t("formSignUp.expiredText")))]),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "signin" } } },
                    [
                      _c("CButton", { attrs: { color: "primary" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("formSignUp.signIn")) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }